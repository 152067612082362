import styled from 'styled-components'

export const Coverages = styled.div`
    background-color: #FEC800;
    display: flex;
    flex-direction: column;
    padding: 30px;
    min-width: 200px;

    @media (max-width: 550px) {
        flex-direction: row;
        padding: 15px;
    }
`

export const ButtonCoverage = styled.button`
    font-family: "Gotham-Bold", sans-serif;
    background-color: ${props => props.action === "delete" ? "red" : "black"};
    color: white;
    padding: 5px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 10px;

    @media (max-width: 550px) {
        margin-top: 0;
        margin-right: ${props => props.action === "delete" ? "none" : "15px"};
    }
`

export const CoverageList = styled.select`
    margin-top: 15px;
    outline: none;

    @media (max-width: 550px) {
        display: none;
    }
`

export const Coverage = styled.option`
    font-family: "Gotham-Medium", sans-serif;
    cursor: pointer;
`