import React, { useState, useCallback } from "react"
import { Input } from "../styled/Input"
import { Results } from "../styled/Results"
import { Result } from "../styled/Result"
import { useAutocomplete } from "../hooks/useAutocomplete"

const Autocomplete = ({ value, onChange }) => {
  const [inputString, setInputString] = useState("")
  const [showResults, setShowResults] = useState(false)
  const { coverageList, loading } = useAutocomplete(inputString)

  const date = new Date()

  /**
   * Update input value
   */
  const handleInputChange = useCallback(
    e => {
      if (!(value.id && e.target.value.length < value.id.length)) {
        setInputString(e.target.value)
      } else {
        if (value) {
          onChange({})
        }
        setInputString(e.target.value)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.id]
  )

  /**
   * Calculate if data end date is less than a month
   * @param {*} endDate 
   */
  const dataValidation = endDate => {
    if (!endDate) {
      // dead_socket case
      return true
    }

    const endDateFormat = new Date(endDate.substring(0, 4), endDate.substring(4, 6) - 1, endDate.substring(6, 8))

    let monthsDiff
    monthsDiff = (endDateFormat.getFullYear() - date.getFullYear()) * 12
    monthsDiff -= date.getMonth() + 1
    monthsDiff += endDateFormat.getMonth()
    return monthsDiff <= 1
  }

  return (
    <>
      {loading ? (
        <img width="28px" height="28px" alt="loading..." src="/thor-assets/loading.gif" />
      ) : (
        <Input
          className={"input-autocomplete"}
          value={(value || {}).id || inputString}
          placeholder="Coverage"
          onChange={handleInputChange}
          onFocus={() => setShowResults(true)}
          onBlur={() => setTimeout(() => setShowResults(false), 300)}
        />
      )}
      {showResults && (
        <Results>
          {coverageList.map((result, index) => (
            <Result
              key={`result-${index}`}
              onClick={() => {
                setShowResults(false)
                onChange(result)
              }}
              status={dataValidation(result.date)}
            >
              {result.id}
              {" - "}
              {result.date === "no_data" ? "Aucune donnée" : result.date === "dead" ? "Dead Instance" : result.date}
            </Result>
          ))}
        </Results>
      )}
    </>
  )
}

export default Autocomplete
