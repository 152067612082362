import styled from 'styled-components'

export const Uploads = styled.div`
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(250px, 0.25fr));
    grid-gap: 10px;

    @media (max-width: 550px) {
        grid-template-columns: 1fr;
    }
`