import styled from 'styled-components'

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const VerticalCenter = styled(Center)`
    height: 100vh;
`

export const Divider = styled.div`
    margin: ${props => props.size || "20px 0"};
    text-align: ${props => props.align === "center" ? "center" : "right"};
`

export const AdminPage = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    @media (max-width: 550px) {
        display: ${props => props.user?.admin ? "flex" : "initial"};
        flex-direction: ${props => props.user?.admin ? "column" : "initial"};
    }
`

export const AdminData = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;

    @media (max-width: 550px) {
        flex-direction: column;
    }
`

export const Header = styled.div`
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 0 30px;
    position: sticky;
    top: 0;

    @media (max-width: 550px) {
        padding: 0 15px;
    }
`

export const Title = styled.span`
    font-size: 2em;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 550px) {
        font-size: 1.2em;
        font-weight: 500;
    }
`
export const Disconnect = styled.div`
    text-align: center;
`

export const UpdateSpace = styled.div`
`

export const Subtitle = styled.div`
    font-family: "Gotham-Bold", sans-serif;
    text-transform: uppercase;
    font-size: 1.5em;
    display: flex;
    flex-direction: column;

    strong {
        text-align: center;
    }
`

export const Informations = styled.span`
    font-family: "Gotham-Medium", sans-serif;
    font-size: 1em;
`

export const UpdateCat = styled.div`
    padding: 10px;
    margin-bottom: ${props => props.button ? "30px" : "0px"};

    @media (max-width: 550px) {
        padding: 10px 0;
    }
`

export const Index = styled.div`
    background: url("/thor-assets/background-grey.svg") #c6c6c5;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 15px;

    @media (max-width: 550px) {
        padding: 5px 15px;
    }
`

export const Options = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    cursor: pointer;
`
export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;

    input {
        margin: -3px 5px 0;
    }

    label {
        cursor: pointer;
    }
`

export const Valkyrie = styled.iframe`
    display: block;
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0 none;
    box-sizing: border-box;
`