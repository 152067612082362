import styled from "styled-components"

export const ModalStyle = styled.div``

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled.div`
  z-index: 100;
  background: #fff;
  position: relative;
  border-radius: 3px;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModalClose = styled.div`
  position: absolute;
  right: 15px;
  top: 10px;
`

export const ModalIcon = styled.img`
  max-height: 100px;
  max-width: 100px;
`

export const ModalText = styled.div`
  text-align: center;
  padding: 10px;
`
