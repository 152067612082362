import React, { useEffect, useState } from "react"
import axios from "axios"

import { UploadArea, UploadContent, UploadName } from "../styled/UploadArea"

const UploadFile = ({ displayName, accept, coverage = null, setIndicator, project, g2n, dante, sheet, env, server, send = false, merge = null }) => {
  const [updateCoverage, setUpdateCoverage] = useState(false)
  /**
   * Get file and give it to server for launch upload if all is ok
   * @param {*} files 
   */
  const handleFiles = async files => {
    const { length } = files

    // Only one file !
    if (length === 1) {
      setIndicator({ type: "loading", message: `Mise à jour des données ${displayName} en cours...` })

      const { name, type } = files[0]
      const acceptType = accept.find(a => a.type === type)
      
      // Check if file given respect extension
      if (acceptType && name.endsWith(acceptType.ext)) {
        const formData = new FormData()
        formData.append("coverage", coverage)
        formData.append("sheet", sheet)
        formData.append("env", env)
        formData.append("server", server)
        formData.append("file", files[0])

        // we put file on server
        const upload = await axios.post(`/mjolnir/upload`, formData, { withCredentials: true })

        if (upload.data && upload.data.status === "error") {
          setIndicator({ type: "error", message: upload.data.error })
        } else if (upload.data && upload.data.status === "done") {
          if (coverage && !sheet) {
            try {
              // we launch update
              const update = await axios(`/mjolnir/update?project=${project}&coverage=${coverage}&file=${upload.data.file}&g2n=${g2n}&dante=${dante}&send=${send}${merge ? "&merge=" + merge + "&name=" + displayName : ""}`)
              let status = undefined

              // every 10 sec call api to know if current job is done
              const waitEndUpdate = (id, status) => {
                if (["done", "failed"].includes(status)) {
                  if (status === "failed") {
                    setIndicator({ type: "error", message: `Erreur lors de la mise à jour des données.` })
                  } else {
                    setIndicator({ type: "success", message: `Mise à jour des données effectuée` })
                  }
                } else {
                  return new Promise(resolve => setTimeout(resolve, 10000)).then(async () => {
                    const response = await axios(`/mjolnir/update-status?id=${id}`)
                    if (response.data) {
                      waitEndUpdate(id, response.data.status)
                    }
                  })
                }
              }

              waitEndUpdate(update.data.job, status)
            } catch (e) {
              setIndicator({ type: "error", message: `Erreur : ${e.response.data.error}` })
            }
          } else if (sheet && env && server) {
            try {
              const synchronize = await axios(`/mjolnir/synchronize?file=${upload.data.file}&project=${sheet}&env=${env}&server=${server}`)
              if (synchronize.data.status === "done") {
                setIndicator({ type: "success", message: `Mise à jour des données effectuée !` })
              }
            } catch (e) {
              setIndicator({ type: "error", message: `Erreur : ${e.response.data.error}` })
            }
          }
        } else {
          setIndicator({ type: "error", message: `Erreur inconnue.` })
        }
      } else {
        setIndicator({ type: "error", message: `Fichier au format incorrect.` })
      }
    } else {
      setIndicator({ type: "error", message: `N'importer qu'un fichier.` })
    }

    // let's reset file to upload it again with same name
    document.querySelector('.file').value = '';
  }

  /**
   * Get file on drop into UploadArea
   * @param {*} e 
   */
  const onDrop = e => {
    e.preventDefault()
    const {
      dataTransfer: { files }
    } = e
    handleFiles(files)
  }

  /**
   * Check if we can update coverage
   */
  useEffect(() => {
    const check = async (coverage) => {
      try {
        const response = await axios.get(`/mjolnir/update-status?id=${coverage}`)
        const status = response.data.status
    
        if (status === "update_available") {
          setUpdateCoverage(true)
        } else {
          setUpdateCoverage(false)
        }
      } catch (error) {
        console.warn("Impossible de savoir si une mise à jour est possible.")
        setUpdateCoverage(false)
      }
    }
  
    if (coverage) {
      check(coverage)
    }
  }, [coverage])

  // we don't want to disable sheet so...
  return (
    sheet
    ? <UploadArea onDrop={e => onDrop(e)} onDragOver={e => e.preventDefault()}>
        <UploadContent>
          {displayName && <UploadName>{displayName}</UploadName>}
          <label htmlFor="file">Choisir votre fichier (.xlsx)</label>
          <input className="file" id="file" type="file" onChange={e => handleFiles(e.target.files)} />
        </UploadContent>
      </UploadArea>
    : <UploadArea disabled={!updateCoverage} onDrop={e => updateCoverage && onDrop(e)} onDragOver={e => updateCoverage && e.preventDefault()}>
        <UploadContent>
          {displayName && <UploadName>{displayName}</UploadName>}
          {updateCoverage ? <>
            <label htmlFor="file">Choisir votre GTFS (.zip)</label>
            <input className="file" id="file" type="file" onChange={e => handleFiles(e.target.files)} />
          </> : <span>Une mise à jour semble déjà en cours...</span>}
        </UploadContent>
      </UploadArea>
  )
}

export default UploadFile
