import { useState, useEffect } from "react"
import axios from "axios"

export const useAutocomplete = string => {
  const [coverageList, setCoverageList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const debounced = setTimeout(() => {
      axios
        .get(`/mjolnir/coverages`, { withCredentials: true })
        .then(response => {
          if (string.length === 0) {
            setCoverageList(response.data)
          } else {
            setCoverageList(response.data.filter(coverage => coverage.id.toLowerCase().includes(string.toLowerCase())))
          }
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
        })
    }, 300)

    return () => {
      clearTimeout(debounced)
    }
  }, [string])

  return { coverageList, loading }
}
