import styled from "styled-components"

export const Result = styled.div`
  padding: 5px;
  background-color: #fff;
  color: ${props => props.status && "red"};
  cursor: pointer;
  &:hover {
    background-color: #dbdbdb;
    color: ${props => props.status && "red"};
  }
`