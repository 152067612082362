import React, { useCallback, useEffect, useState } from "react"
import { ModalStyle, Overlay, ModalClose, ModalContent, ModalIcon, ModalText } from "../styled/Modal"
import { Button } from "../styled/Button"
import { Input } from "../styled/Input"

const Modal = ({ displayModal, changeDisplayModal, content }) => {
  const { coverage } = content
  const [message, setMessage] = useState(null)
  const [type, setType] = useState(null)
  const [value, setValue] = useState("")

  /**
   * Update input value
   */
  const handleInputChange = useCallback(
    e => {
      setValue(e.target.value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.id]
  )

  /**
   * Verification
   */
  const deleteCoverage = () => {
    if (coverage && coverage === value) {
      setType("loading")
      setMessage(`Supression en cours de ${coverage}`)
      content.setAutorizedDel(true)
    } else {
      setType("error")
      setMessage(`Impossible de valider la suppression de ${coverage}`)
    }
  }

  /**
   * Detect display modal use
   */
  useEffect(() => {
    setMessage(content.message)
    setType(content.type)
    setValue("")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayModal])

  /**
   * Detect modal changes
   */
  useEffect(() => {
    setMessage(content.message)
    setType(content.type)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  return displayModal && message ? (
    <ModalStyle>
      <Overlay>
        <ModalContent>
          <ModalClose>
            {type !== 'loading' && <Button onClick={() => changeDisplayModal()}>&times;</Button>}
          </ModalClose>
          {type && <ModalIcon src={`/thor-assets/${type}.${type === "loading" ? "gif" : "svg"}`} />}
          <ModalText>{message}</ModalText>
          {type === 'loading' && <ModalText>Veuillez ne pas fermer ni rafraichir la page.</ModalText>}
          {type === 'delete' && <>
            <ModalText>Cette action est définitive, si vous êtes sûr de vouloir supprimer l'instance {coverage} veuillez saisir son nom et valider.</ModalText>
            <Input
              className={"input-delete"}
              delete
              value={value}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  deleteCoverage()
                }
              }}
            />
            <Button onClick={() => deleteCoverage()}>Supprimer</Button>
          </>}
        </ModalContent>
      </Overlay>        
    </ModalStyle>
  ) : null
}

export default Modal
