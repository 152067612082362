import { useState } from 'react';

export const useModal = () => {
  const [displayModal, setDisplayModal] = useState(false);

  const changeDisplayModal = () => {
    setDisplayModal(!displayModal)
  }

  return {
    displayModal,
    changeDisplayModal
  }
};