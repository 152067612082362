import styled, { css } from 'styled-components'

export const Button = styled.button`
    font-family: "Gotham-Bold", sans-serif;
    background-color: #FEC800;
    color: black;
    padding: ${props => props.size || "6px 10px 4px"};
    border: none;
    border-radius: 2px;
    cursor: pointer;
    white-space: nowrap;
    ${props => props.disabled ? css`background: red;` : css`background: #FEC800;`};
`