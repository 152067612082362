import React, { useEffect, useState } from "react"
import Admin from "./components/Admin"
import axios from "axios"
import { Login, Title, Form, Entry, Button, Error } from "./styled/Login"
import { VerticalCenter } from "./styled/Index"

function App() {
  const [username, setUsername] = useState(null)
  const [usernameInput, setUsernameInput] = useState("")
  const [password, setPassword] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [connectedUser, setConnectedUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  /**
   * Check with cookies if user is already authentificated
   */
  const checkConnection = async () => {
    setLoading(true);

    try {
      const already = await axios.get("/mjolnir/", {
        withCredentials: true
      })
      if (already.data.connected) {
        setConnectedUser(already.data)
      }
    } catch (error) {
      console.log("can't check if already connect")
    } finally {
      setLoading(false);
    }
  }

  /**
   * Login request
   */
  const login = async () => {
    setLoading(true)

    try {
      const connect = await axios.post("/mjolnir/login", {
        username
      }, {
        withCredentials: true,
        auth: {
          username,
          password
        }
      })

      setLoading(false)
      setSubmitted(false)
      setUsernameInput("")
      setPassword("")
      setConnectedUser(connect.data)
    } catch (error) {
      setError("Veuillez vérifier vos informations de connexion.")
    }
  }

  /**
   * Check if user is already connected at launch
   */
  useEffect(() => {
    checkConnection()
  }, [])
  
  /**
   * Submit action
   */
  useEffect(() => {
    if (submitted) {
      if (!usernameInput && !password) {
        setError("Veuillez rentrer vos informations de connexion.")
      } else if (!usernameInput) {
        setError("Veuillez rentrer votre nom d'utilisateur.")
      } else if (!password) {
        setError("Veuillez rentrer votre mot de passe.")
      } else {
        login()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted])

  /**
   * If error dropped
   */
  useEffect(() => {
    if (error) {
      setPassword("")
      setSubmitted(false)
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  /**
   * To remove username in input but keep it
   */
  useEffect(() => {
    setUsername(usernameInput)
  }, [usernameInput])

  return loading ? (
    <VerticalCenter>
      <img src="/thor-assets/loading.gif" alt="loading" />
    </VerticalCenter>
  ) : !connectedUser ? (
    <Login>
      <Title>Connexion à l'interface d'administration</Title>
      <Form onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            setError("");
            setSubmitted(true);
          }
        }} onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setError("");
          setSubmitted(true);
        }}>
        <Entry>
          <label htmlFor="username">Nom d'utilisateur</label>
          <input id="username" type="text" name="username" value={usernameInput} autoCapitalize="none" onChange={(e) => setUsernameInput(e.target.value)} autoComplete="username" />
        </Entry>
        <Entry>
          <label htmlFor="password">Mot de passe</label>
          <input id="password" type="password" name="password" value={password} autoCapitalize="none" onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" />
        </Entry>
        <>
          <Button disabled={loading} onClick={() => {
            setError("")
            setSubmitted(true)
          }}>
            Se connecter
          </Button>
          {loading && (
            <img src="/thor-assets/loading.gif" alt="loading" />
          )}
        </>
        {error && error.length && <Error><strong>Erreur</strong> - {error}</Error>}
      </Form>
    </Login>
  ) : (
    <Admin user={connectedUser} setConnectedUser={setConnectedUser} />
  )
}

export default App
