import React, { useEffect, useState } from "react"
import Autocomplete from "./Autocomplete"
import Modal from "./Modal"
import UploadFile from "./UploadFile"
import axios from "axios"
import { useModal } from "../hooks/useModal"
import { useAutocomplete } from "../hooks/useAutocomplete"

import { Button } from "../styled/Button"
import { Coverages, ButtonCoverage, CoverageList, Coverage } from "../styled/Coverages"
import { AdminPage, AdminData, Header, Disconnect, Divider, Title, UpdateSpace, Subtitle, Informations, UpdateCat, Index, CheckboxContainer, Valkyrie } from "../styled/Index"
import { Uploads } from "../styled/Uploads"
const jwt = require('jsonwebtoken');

const Admin = ({ user, setConnectedUser }) => {
  const { displayModal, changeDisplayModal } = useModal()
  const [coverage, setCoverage] = useState({})
  const [canUpdateData, setCanUpdateData] = useState(false)
  const { coverageList } = useAutocomplete("")
  const [autorizedDel, setAutorizedDel] = useState(false)
  const [indicator, setIndicator] = useState({ type: null, message: null })
  const [valkyrieKey, setValkyrieKey] = useState(null)
  const [g2n, setG2n] = useState(false)
  const [dante, setDante] = useState(true)

  /**
   * Create coverage from input
   */
  const createCoverage = async () => {
    const input = document.querySelector(".input-autocomplete").value
    if (input.length) {
      setIndicator({ type: "loading", message: `Création de l'instance ${input} en cours...` })

      try {
        const response = await axios.get(`/mjolnir/create-coverage?coverage=${input}`, { withCredentials: true })
        if (response.data) {
          setCoverage({ id: input })
          setIndicator({ type: "success", message: `Instance ${input} créée` })
        }
      } catch (e) {
        setIndicator({ type: "error", message: `Erreur : ${e.response.data.error}` })
      }
    } else {
      setIndicator({ type: "error", message: "Veuillez saisir un nom d'instance à créer." })
    }
  }

  /**
   * Logout request
   */
  const logout = async () => {
    const request = await axios.get("/mjolnir/logout")
    if (!request.data?.connected) {
      setConnectedUser(null)
    }
  }

  /**
   * Launch an update of data on website
   */
  const launchUpdate = async () => {
    setIndicator({ type: "loading", message: `Mise à jour des données en cours...` })
    setCanUpdateData(false)

    try {
      const response = await axios.get(`/mjolnir/map-data-update?type=launch&value=${user.button.port}&server=${user.button.server}`)
      if (response?.data?.id === "data_generation_success") {
        setIndicator({ type: "success", message: `Mise à jour des donées effectuée avec succès.` })
        setCanUpdateData(true)        
      } else {
        setIndicator({ type: "error", message: `Erreur : La mise à jour des données a échouée.` })
        setCanUpdateData(true)
      }
    } catch (error) {
      setIndicator({ type: "error", message: `Erreur : La mise à jour des données de la carte interactive n'a pas pu aboutir.` })
      setCanUpdateData(true)
    }
  }

  /**
   * Detect if button for update data on website is available
   */
  useEffect(() => {
    if (user.valkyrie) {
      jwt.sign({project: user.projet}, `${user.project}_2y10czt7za/HESOfQKDNPm3y4eoBnFWxdNCB3SK4YneGTxAsSOzEqj52K`, { expiresIn: '1d' }, (error, token) => {
        if (error) {
          console.log("tok", error)
        } else {
          console.log("token", token)
          setValkyrieKey(token)
        }   
      });
    }

    const canIUpdate = async (user) => {
      try {
        const response = await axios.get(`/mjolnir/map-data-update?type=state&value=${user.button.port}&server=${user.button.server}`)
        setCanUpdateData(response.data.updatable)
      } catch (error) {
        console.warn("Impossible de savoir si une mise à jour est possible.")
        setCanUpdateData(false)
      }
    }

    if (user.button) {
      canIUpdate(user)
    }
  }, [user])

  /**
   * Display messages for loading, success || error actions
   */
  useEffect(() => {
    if (!displayModal && indicator.message) {
      changeDisplayModal()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicator])

  /**
   * Delete coverage selected
   */
  useEffect(() => {
    const deleteCoverage = async () => {
      try {
        const response = await axios.get(`/mjolnir/delete-coverage?coverage=${coverage.id}`, { withCredentials: true })
        if (response.data) {
          setIndicator({ type: "success", message: `Instance ${coverage.id} supprimée.` })
          setCoverage({})
        }
      } catch (e) {
        setIndicator({ type: "error", message: `Erreur : ${e.response.data.error}` })
      }
    }

    if (autorizedDel) {
      deleteCoverage(autorizedDel)
      setAutorizedDel(false)
    }

  }, [autorizedDel, coverage])

  return (
    <AdminPage user={user}>
      <Header>
        <Title>Interface d'administration {user.username}</Title>
        <Disconnect>
          <Button onClick={() => logout()}>Se déconnecter</Button>
        </Disconnect>
      </Header>
      <AdminData>
        {user.admin && <>
          <Coverages>
            <Autocomplete value={coverage} onChange={setCoverage} />
            <ButtonCoverage onClick={() => createCoverage()}>Créer l'instance</ButtonCoverage>
            {coverage && coverage.id && <ButtonCoverage action="delete" onClick={() => setIndicator({ type: "delete", coverage: coverage.id, message: `Suppression de ${coverage.id} ?`, setAutorizedDel })}>Supprimer l'instance</ButtonCoverage>}
            <CoverageList size={coverageList.length + 1} defaultValue value={coverage.id}>
              <option hidden disabled value />
              {coverageList.sort((a, b) => a.id.localeCompare(b.id)).map(c => {
                return <Coverage key={c.id} onClick={() => setCoverage(c)}>{c.id}</Coverage>
              })}
            </CoverageList>
          </Coverages>
        </>}
        <Index>
          <Modal displayModal={displayModal} changeDisplayModal={() => changeDisplayModal()} content={indicator} />
          { user.admin && coverage && coverage.id ?
            <>
              <Subtitle>
                Mettre à jour les données sur le coverage :
                <strong>{coverage.id}</strong>
              </Subtitle>
              <Divider size={"20px 0 0"}>
                <CheckboxContainer>
                  <input id="g2n" type="checkbox" checked={g2n} onChange={() => setG2n(!g2n)} />
                  <label htmlFor="g2n">Le fichier que je mets est déjà un NTFS</label>
                </CheckboxContainer>
                <CheckboxContainer>
                  <input id="dante" type="checkbox" checked={dante} onChange={() => setDante(!dante)} />
                  <label htmlFor="dante">Récupérer les tracés aux lignes dans Navitia</label>
                </CheckboxContainer>
              </Divider>
              <Divider>
                <UploadFile
                  accept={[
                    { type: "application/x-zip-compressed", ext: ".zip" },
                    { type: "application/zip", ext: ".zip" },
                    { type: "", ext: ".osm.pbf" },
                  ]}
                  project={user.project}
                  displayName={coverage.id}
                  coverage={coverage.id}
                  indicator={indicator}
                  setIndicator={setIndicator}
                  g2n={!g2n}
                  dante={dante}
                />
              </Divider>
            </>
          :
            <UpdateSpace>
              {user?.button && (
                <UpdateCat button={true}>
                  <Subtitle>Mettre manuellement les données de la carte interactive à jour</Subtitle>
                  <Divider size={"10px 0 0"} align={"center"}>
                    <Button disabled={!canUpdateData} size={"15px 20px 13px"} onClick={launchUpdate}>Lancer la mise à jour</Button>
                  </Divider>
                </UpdateCat>
              )}
              {user?.gtfs && (
                <UpdateCat>
                  <Subtitle>Mettre à jour mes données GTFS</Subtitle>
                  <Informations>
                    <span role="img" aria-label="attention">⚠️</span> Attention à bien sélectionner un .zip
                  </Informations>
                  <Divider size={"10px 0 0"}>
                    <Uploads>
                      {user.gtfs.filter(i => !i.url).map((field) => {
                        const gtfsToMerge = field.merge ? user.gtfs.filter(g => g.merge).map(g => g.name).join(',') : null

                        return (
                          <UploadFile
                            key={field.name}
                            accept={[
                              { type: "application/x-zip-compressed", ext: ".zip" },
                              { type: "application/zip", ext: ".zip" }
                            ]}
                            project={user.project}
                            displayName={field.name}
                            coverage={field.coverage}
                            indicator={indicator}
                            setIndicator={setIndicator}
                            g2n={true}
                            dante={field.dante}
                            send={user?.sendGTFS || false}
                            merge={gtfsToMerge?.length ? gtfsToMerge : null}
                          />
                        )
                      })}
                    </Uploads>
                  </Divider>
                </UpdateCat>
              )}
              {user?.xlsx && (
                <UpdateCat>
                  <Subtitle>Mettre à jour mes données XLSX</Subtitle>
                  <Informations>
                    <span role="img" aria-label="attention">⚠️</span> Attention à ne pas changer la stucture du fichier Excel et à bien sélectionner un fichier de type xlsx.
                  </Informations>
                  <Divider size={"10px 0 0"}>
                    <Uploads>
                      {user.xlsx.map((field) => {
                        return (
                          <UploadFile
                            key={field.name}
                            project={user.project}
                            displayName={field.name}
                            sheet={user.project}
                            env={user.env}
                            server={field.server}
                            accept={[
                              { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ext: ".xlsx" }
                            ]}
                            indicator={indicator}
                            setIndicator={setIndicator}
                          />
                        )
                      })}
                    </Uploads>
                  </Divider>
                </UpdateCat>
              )}
              {user?.valkyrie && (<UpdateCat>
                <Subtitle>Mettre à jour les données en base</Subtitle>
                  <Informations>
                    <span role="img" aria-label="attention">⚠️</span> Attention à bien sélectionner un .zip
                  </Informations>
                  <Divider size={"10px 0 0"}>
                  <Valkyrie src={`https://valkyrie.lc.tools/${user.project}/rows?key=${valkyrieKey}`} frameborder="0" referrerpolicy="origin"/>
                  </Divider>
              </UpdateCat>)}
            </UpdateSpace>
          }
        </Index>
      </AdminData>
    </AdminPage>
  )
}

export default Admin