import styled, { css } from "styled-components"

export const UploadArea = styled.div`
  font-family: "Gotham-Bold", sans-serif;
  height: 200px;
  border-style: none;
  ${props => props.disabled ? css`background-color: red;` : css`background-color: #FEC800;`};
  color: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UploadContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  justify-content: space-evenly;
  padding: 0 15px;
  
  label {
    background-color: black;
    color: white;
    border-radius: 2px;
    padding: 10px;
    cursor: pointer;
  }

  input {
    display: none;
	  z-index: -1;
  }
`

export const UploadName = styled.div`
  font-size: 1.7em;
`