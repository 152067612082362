import styled from "styled-components"

export const Input = styled.input`
    font-family: "Gotham-Medium", sans-serif;
    padding: 5px;
    border: ${props => props.delete ? "2px solid black" : "none"};
    border-radius: 2px;
    margin-bottom: ${props => props.delete ? "10px" : "none"};

    @media (max-width: 550px) {
        margin-right: 15px;
        flex: 1;
        font-size: 16px;
    }
`